import { bindable } from 'aurelia-framework';
import * as noUiSlider from 'nouislider';
import { Utilities } from '../../utilities/utilities';

export class MultiRangeSlider {
    private element: HTMLElement;
    @bindable() private onChange: (...values) => void;
    @bindable() private values: number[];
    @bindable() private start: number[] = [75, 100];
    @bindable() private connect: boolean = true;
    @bindable() private step: number = 1;
    @bindable() private tooltips: boolean = true;
    @bindable() private range: { min: number, max: number } = { min: 0, max: 100 };
    @bindable() private direction: 'ltr' | 'rtl' = 'ltr';
    @bindable() private tooltipFormatter: (value: number) => string = (value: number) => value.toString();
    @bindable() private pipFormatter: (value: number) => string = (value: number) => value.toString();
    private slider: noUiSlider.API;

    public reset(): void {
        // Set any previously selected values back to the start values
        this.values = this.start;

        if (this.slider)
            this.slider.set(this.start);
    }

    public set(values: number[]): void {
        this.values = values;

        if (this.slider)
            this.slider.set(values);
    }

    private attached(): void {
        this.construct();
    }

    private detached(): void {
        this.destruct();
    }

    private construct(): void {
        if (!this.values) {
            this.values = this.start;
        }

        this.slider = noUiSlider.create(this.element, {
            start: this.values,
            connect: this.connect,
            range: this.range,
            direction: this.direction,
            pips: {
                mode: noUiSlider.PipsMode.Count,
                values: 3,
                format: { to: (value: number) => this.pipFormatter(value) },
            },
            tooltips: [
                { to: (value: number) => this.tooltipFormatter(value) },
                { to: (value: number) => this.tooltipFormatter(value) },
            ],
            step: this.step
        });

        // Adds the change handler
        (this.element as any).noUiSlider.on('change', this.changeHandler);
    }

    private destruct(): void {
        // Removes the change handler
        (this.element as any).noUiSlider.off('change', this.changeHandler);
        // Destroys the slider
        (this.element as any).noUiSlider.destroy();
    }

    private changeHandler = (formattedValues: any[], handle: number, rawValues: number[], tap: any, position: any): void => {
        this.values = rawValues;

        if (this.onChange)
            this.onChange(this.values);
    };
}
